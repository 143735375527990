<template>
  <div class="video__wrapper" id="video__wrapper">
    <div class="video__notification" v-if="!signalConnectToCall">
      <span class="white--text"
        >{{
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.mainPage.webRTC.nextSessionWillBee"
          )
        }}: {{ nextSessionInfo }}</span
      >
      <span class="white--text">{{
        this.$vuetify.lang.t(
          "$vuetify.main.psychologistDashboard.mainPage.webRTC.textInformationInVideoPlayer"
        )
      }}</span>
      <v-btn
        v-if="callIsGoingNow"
        @click="connectToCall"
        depressed
        color="success"
        >{{
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.mainPage.webRTC.connectToCallBtn"
          )
        }}</v-btn
      >
    </div>
    <div
      class="video__notification_spinner"
      v-if="showSpinnerWrapperWithMessage"
    >
      <div class="d-flex">
        <v-progress-circular
          v-if="showSpinner"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <span class="ml-5 font-weight-medium align-self-center white--text">
          {{ messageForSpinner }}</span
        >
      </div>
    </div>
    <v-dialog v-model="handleDialog" max-width="500">
      <v-card>
        <v-card-title>
          {{
            this.$vuetify.lang.t(
              "$vuetify.main.psychologistDashboard.mainPage.webRTC.rateTheSession"
            )
          }}
        </v-card-title>

        <div class="d-flex justify-center">
          <v-rating
            @click="dialog = false"
            v-model="rating"
            background-color="grey"
            color="yellow accent-4"
            dense
            hover
            size="28"
          ></v-rating>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="align-self-end"
            text
            @click="sendRating"
            >{{
              this.$vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.mainPage.webRTC.rateBtn"
              )
            }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="openSettings" class="settings">
      <span class="font-weight-bold align-self-center">{{
        this.$vuetify.lang.t(
          "$vuetify.main.psychologistDashboard.mainPage.webRTC.settings"
        )
      }}</span>
      <div>
        <v-select
          v-model="mediaDevices.currentVideoTrack.label"
          :items="this.mediaDevices.videoLabels"
          menu-props="auto"
          label="Выберите"
          hide-details
          prepend-icon="mdi-video"
          single-line
        ></v-select>
      </div>
      <div>
        <v-select
          v-model="mediaDevices.currentAudioTrack.label"
          :items="this.mediaDevices.audioLabels"
          menu-props="auto"
          label="Select"
          hide-details
          prepend-icon="mdi-microphone"
          single-line
        ></v-select>
      </div>
    </div>
    <video
      class="video__player_client"
      muted
      autoplay
      playsinline
      id="video-player-client"
      v-show="signalConnectToCall"
    ></video>
    <video
      class="video__player"
      autoplay
      id="video-player"
      v-show="signalConnectToCall"
    ></video>
    <div
      class="video__btn_controls"
      v-show="signalConnectToCall"
      id="video__btn_controls"
    >
      <div class="d-flex">
        <div class="d-flex">
          <v-icon
            class="icon__control icon_gray"
            v-show="!handleVideo"
            @click="stopVideoOrSound('video')"
            >mdi-video</v-icon
          >
          <v-icon
            class="icon__control icon_gray"
            v-if="handleVideo"
            @click="stopVideoOrSound('video')"
            >mdi-video-off</v-icon
          >
        </div>

        <div class="d-flex">
          <v-icon
            class="icon__control icon_gray"
            v-if="!handleMicrophone"
            @click="stopVideoOrSound('microphone')"
            >mdi-microphone</v-icon
          >
          <v-icon
            class="icon__control icon_gray"
            v-if="handleMicrophone"
            @click="stopVideoOrSound('microphone')"
            >mdi-microphone-off</v-icon
          >
        </div>

        <div id="sound-bar">
          <v-icon
            class="icon__control icon_gray"
            v-if="handleSound"
            @click="handleSoundMethod"
            >mdi-volume-mute</v-icon
          >
          <v-icon
            class="icon__control icon_gray"
            v-if="!handleSound"
            @click="handleSoundMethod('mute')"
            >mdi-volume-source</v-icon
          >
          <v-slider
            v-if="!handleSound"
            class="slider align-center"
            v-model="handleSoundValue"
            :thumb-color="sliderSetting.color"
            hide-details
            hide-spin-buttons
            max="100"
            min="0"
            thumb-label="always"
          ></v-slider>
        </div>
      </div>

      <span class="white--text current_time">{{ currentTime }}</span>

      <div class="d-flex">
        <div class="d-flex">
          <v-icon
            class="icon__control_settingns icon_gray"
            @click="this.openSettingsMenu"
            >mdi-cog</v-icon
          >
        </div>
        <div class="d-flex">
          <v-icon
            class="icon__control icon_gray"
            v-if="!handleFullScreen"
            @click="handleVideoToFullScreen"
            >mdi-arrow-expand-all</v-icon
          >
          <v-icon
            class="icon__control icon_gray"
            v-if="handleFullScreen"
            @click="handleVideoToFullScreen"
            >mdi-arrow-collapse-all</v-icon
          >
        </div>

        <div class="d-flex">
          <v-icon @click="hangUpCall" class="icon__control_decline icon_warning"
            >mdi-phone-off</v-icon
          >
        </div>
      </div>
    </div>
    <input type="text" v-model="someDataFromCustomInput" />
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Peer from "simple-peer";
import { mapGetters } from "vuex";
import Vue from "vue";
export default {
  name: "WebRTCPlayer",
  data() {
    return {
      clientVideoElement: null,
      wrapperVideoElement: null,
      videoBtnsControl: null,
      someDataFromCustomInput: "",
      handleVideo: false,
      handleMicrophone: false,
      handleSound: false,
      handleSoundValue: 50,
      sliderSetting: {
        label: "thumb-color",
        color: "red",
      },
      handleFullScreen: false,
      signalConnectToCall: false,
      currentTime: null,
      userInSession: [],
      callSocketChannel: null,
      mainSocketChannel: null,
      signalIfUser2Connected: false,
      ourStream: null,
      ourOffer: null,
      ourAnswer: null,
      remoteStream: null,
      remoteOffer: null,
      remoteAnswer: null,
      peer: null,
      userAgent: null,
      consts: {
        USER_ROLE_PATIENT: "patient",
        USER_ROLE_PSYCHOLOGIST: "psychologist",
        sizeOfClientPlayer: {
          fullScreen: false,
          onFullScreen: {
            type: "fullscreen",
            resolutions: {
              default: {
                height: 250,
                width: 500,
              },
              dynamic: {
                height: null,
                width: null,
              },
            },
            position: {
              top: 0,
              left: 0,
            },
          },
          onDefault: {
            type: "default",
            height: 100,
            width: 200,
            position: {
              top: 200,
              left: 480,
            },
          },
        },
      },
      callStatus: {
        status: "",
        statuses: {
          CALL_STARTED: "started",
          CALL_CONNECTED_TO_LOBBY: "connected",
          CALL_TRY_TO_CONNECT: "try",
          CALL_HANGUP: "hangup",
          CALL_WAITING_FOR_CONNECTION_TO_CALL: "waiting",
        },
      },
      remoteStatus: {
        status: "",
        whoConnected: "",
      },
      callIsGoingNow: false,
      socketHere: [],
      messageForSpinner: "",
      showMessageIfUserTwoConnected: true,
      showSpinnerWrapperWithMessage: false,
      showSpinner: false,
      intervalId: null,
      dialog: false,
      rating: 0,
      currentCallId: null,
      openSettings: false,
      typeOfFullScreenByBrowser: null,
      mediaDevices: {
        audio: [],
        audioLabels: [],
        video: [],
        videoLabels: [],
        currentAudioTrack: {
          label: "",
          item: {},
        },
        currentVideoTrack: {
          label: "",
          item: {},
        },
      },
      nextSessionInfo: "",
    };
  },
  watch: {
    handleSoundValue: function () {
      if (this.handleSoundValue <= 0) {
        this.handleSound = !this.handleSound;
      }
      this.muteVolume();
    },
    "callStatus.status": {
      // eslint-disable-next-line no-unused-vars
      handler: function (after, before) {
        this.onStatusChange(after);
      },
      deep: true,
    },
    "remoteStatus.status": {
      handler: function () {
        this.onRemoteStatusChange();
      },
      deep: true,
    },
    "mediaDevices.currentAudioTrack.label": {
      handler: function () {
        this.replaceMediaTrackInPeer("audio");
      },
      deep: true,
    },
    "mediaDevices.currentVideoTrack.label": {
      handler: function () {
        this.replaceMediaTrackInPeer("video");
      },
      deep: true,
    },
  },
  props: {
    nextSession: String,
    updateCurrentClient: Function,
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    handleDialog: function () {
      return this.user.role === this.consts.USER_ROLE_PATIENT && this.dialog;
    },
  },
  mounted() {
    if (
      this.user.role === this.consts.USER_ROLE_PSYCHOLOGIST &&
      this.user.nearest_booked_time_slot_model
    ) {
      this.updateCurrentClient(
        this.user.nearest_booked_time_slot_model.id || ""
      );
    }
    this.getNextSession();
    this.setCallStatus(
      this.callStatus.statuses.CALL_WAITING_FOR_CONNECTION_TO_CALL
    );

    this.clientVideoElement = document.getElementById("video-player-client");
    this.wrapperVideoElement = document.getElementById("video__wrapper");
    this.videoBtnsControl = document.getElementById("video__btn_controls");

    this.userAgent = window.navigator.userAgent;
    this.setSizeOfClientVideoEl(this.consts.sizeOfClientPlayer.onDefault.type);
    this.checkTypeOfBrowser();
    document.addEventListener(
      this.typeOfFullScreenByBrowser,
      this.checkFullScreen,
      false
    );

    window.addEventListener("resize", this.checkSizeOfWrapperVideoElement);

    this.wrapperVideoElement.addEventListener("mouseenter", () => {
      this.onMouseEnterWrapper = true;
    });

    this.wrapperVideoElement.addEventListener("mouseleave", () => {
      this.onMouseEnterWrapper = false;
    });

    this.clientVideoElement.addEventListener("mousedown", this.handleMove);

    this.clientVideoElement.addEventListener("mouseup", () => {
      this.handleStopMoving("video__wrapper");
    });
    this.processOfCreateMainSocketChannel();
    this.intervalId = setInterval(() => this.setTimeInVideo(), 1000);
  },
  destroyed() {
    if (this.mainSocketChannel) {
      // eslint-disable-next-line no-undef
      Echo.leave(this.mainSocketChannel.name);
    }
    if (this.callSocketChannel) {
      // eslint-disable-next-line no-undef
      Echo.leave(this.callSocketChannel.name);
    }
    clearInterval(this.intervalId);
    document.removeEventListener(
      this.typeOfFullScreenByBrowser,
      this.checkFullScreen
    );
    document.removeEventListener("mouseenter", function () {});
    document.removeEventListener("mouseleave", function () {});
    document.removeEventListener("mousedown", this.handleMove);
    this.hangUpCall();
    // eslint-disable-next-line no-undef
    Echo.disconnect();
  },
  methods: {
    checkTypeOfBrowser() {
      let userAgent = navigator.userAgent;

      if (userAgent.indexOf("Chrome") !== -1) {
        this.typeOfFullScreenByBrowser = "fullscreenchange";
      } else if (userAgent.indexOf("Safari") !== -1) {
        this.typeOfFullScreenByBrowser = "webkitfullscreenchange";
      } else if (userAgent.indexOf("Firefox") !== -1) {
        this.typeOfFullScreenByBrowser = "fullscreenchange";
      } else {
        this.typeOfFullScreenByBrowser = "fullscreenchange";
      }
    },
    getNextSession() {
      switch (this.user.role) {
        case this.consts.USER_ROLE_PSYCHOLOGIST:
          this.nextSessionInfo = this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.mainPage.webRTC.scanningNextSession"
          );

          this.$store.dispatch("user/getProfile", {}).then(() => {
            if (this.user.nearest_booked_not_current_time_slot) {
              this.nextSessionInfo =
                this.user.nearest_booked_not_current_time_slot;
            } else {
              this.nextSessionInfo = "-";
            }
          });
          break;
        case this.consts.USER_ROLE_PATIENT:
          this.$store.dispatch("user/getProfile", {}).then(() => {
            this.nextSessionInfo = this.$vuetify.lang.t(
              "$vuetify.main.psychologistDashboard.mainPage.webRTC.scanningNextSession"
            );
            if (this.user.time_slots[0]) {
              this.nextSessionInfo =
                this.user.time_slots[0].date.translated +
                " " +
                this.user.time_slots[0].slots[0].time;
            } else {
              this.nextSessionInfo = "-";
            }
          });
          break;
      }
    },
    getNewConstraints(device) {
      switch (device.kind) {
        case "videoinput":
          return {
            video: {
              deviceId: { exact: device.deviceId },
              width: { min: 640, ideal: 1280, max: 1920 },
              height: { min: 480, ideal: 720, max: 1080 },
              facingMode: "user",
              frameRate: {
                min: 24,
                ideal: 30,
                max: 60,
              },
            },
          };
        case "audioinput":
          return {
            audio: {
              deviceId: {
                exact: device.deviceId,
              },
            },
          };
      }
    },
    setClientStreamInClientVideoElement(stream) {
      if (typeof this.clientVideoElement.srcObject === "object") {
        this.clientVideoElement.srcObject = stream;
      } else {
        this.clientVideoElement.src = window.URL.createObjectURL(stream);
      }
    },
    replaceMediaTrackInPeer: function (typeOfChange) {
      if (this.openSettings) {
        let oldAudioTrack = this.ourStream.getAudioTracks()[0];
        let oldVideoTrack = null;
        let newAudioDevice = this.mediaDevices.audio.filter(
          (device) => device.label === this.mediaDevices.currentAudioTrack.label
        )[0];

        let newVideoDevice = null;
        // eslint-disable-next-line no-unused-vars
        let getUserMedia = null;

        switch (typeOfChange) {
          case "video":
            oldVideoTrack = this.mediaDevices.currentVideoTrack.item;
            newVideoDevice = this.mediaDevices.video.filter(
              (device) =>
                device.label === this.mediaDevices.currentVideoTrack.label
            );
            if (this.peer) {
              navigator.mediaDevices
                .getUserMedia(this.getNewConstraints(newVideoDevice[0]))
                // eslint-disable-next-line no-unused-vars
                .then((newStream) => {
                  this.setClientStreamInClientVideoElement(newStream);
                  this.peer.replaceTrack(
                    this.ourStream.getVideoTracks()[0],
                    newStream.getVideoTracks()[0],
                    this.ourStream
                  );

                  oldVideoTrack.stop();
                  this.setCurrentMediaTracks(newStream);
                });
            }
            break;
          case "audio":
            if (this.peer) {
              try {
                getUserMedia = navigator.mediaDevices
                  .getUserMedia(this.getNewConstraints(newAudioDevice))
                  .catch((error) => {
                    throw new Error(error);
                  });
                getUserMedia.then((newStream) => {
                  this.peer.replaceTrack(
                    this.ourStream.getAudioTracks()[0],
                    newStream.getAudioTracks()[0],
                    this.ourStream
                  );

                  this.setCurrentMediaTracks(newStream);
                });
              } catch (error) {
                if (error.name !== "NotReadableError") throw error;

                oldAudioTrack.stop();

                getUserMedia = navigator.mediaDevices.getUserMedia(
                  this.getNewConstraints(newAudioDevice)
                );

                getUserMedia.then((newStream) => {
                  this.peer.replaceTrack(
                    this.ourStream.getAudioTracks()[0],
                    newStream.getAudioTracks()[0],
                    this.ourStream
                  );

                  this.setCurrentMediaTracks(newStream);
                });
              }
              // eslint-disable-next-line no-unused-vars
            }
            break;
        }
      }
    },
    openSettingsMenu() {
      if (!this.openSettings) {
        this.getUserMediaDevices();
      }
      this.openSettings = !this.openSettings;
    },
    getUserMediaDevices() {
      let _this = this;
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        devices.forEach(function (device) {
          switch (device.kind) {
            case "videoinput":
              _this.mediaDevices.video.push(device);
              _this.mediaDevices.videoLabels.push(device.label);
              break;
            case "audioinput":
              _this.mediaDevices.audio.push(device);
              _this.mediaDevices.audioLabels.push(device.label);
              break;
          }
        });
      });
    },
    sendRating() {
      this.dialog = false;
      this.$store
        .dispatch("user/sendRatingAfterSession", {
          id: this.currentCallId,
          rating: this.rating,
        })
        .then((response) => {
          Vue.swal({
            target: document.getElementById("main"),
            text: response.data.message,
            icon: "success",
            timer: 4000,
            toast: true,
            position: "top-right",
            showConfirmButton: false,
          });
        });
    },
    getMessageForSpinner() {
      switch (this.callStatus.status) {
        case this.callStatus.statuses.CALL_CONNECTED_TO_LOBBY:
          this.messageForSpinner = "";
          this.messageForSpinner = this.handleInitiator()
            ? this.$vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.mainPage.webRTC.psychologistSpinnerMessage"
              )
            : this.$vuetify.lang.t(
                "$vuetify.main.psychologistDashboard.mainPage.webRTC.clientSpinnerMessage"
              );
          break;
        case this.callStatus.statuses.CALL_TRY_TO_CONNECT:
          this.messageForSpinner = "";
          this.messageForSpinner = this.$vuetify.lang.t(
            "$vuetify.main.psychologistDashboard.mainPage.webRTC.connection"
          );
          break;
      }
    },
    // eslint-disable-next-line no-unused-vars
    setCallStatus(typeOfStatus) {
      this.callStatus.status = typeOfStatus;
    },
    onStatusChange(status) {
      switch (status) {
        case this.callStatus.statuses.CALL_CONNECTED_TO_LOBBY:
          this.getMessageForSpinner();
          this.showSpinnerWrapperWithMessage = true;
          break;
        case this.callStatus.statuses.CALL_STARTED:
          this.showSpinnerWrapperWithMessage = false;
          this.showSpinner = false;
          break;
        case this.callStatus.statuses.CALL_TRY_TO_CONNECT:
          this.getMessageForSpinner();
          break;
        case this.callStatus.statuses.CALL_HANGUP:
          this.getNextSession();
          this.remoteStatus = {};
          this.showSpinnerWrapperWithMessage = false;
          this.ourStream = null;
          this.remoteStream = null;
          break;
        case this.callStatus.statuses.CALL_WAITING_FOR_CONNECTION_TO_CALL:
          break;
      }
    },
    onRemoteStatusChange() {
      switch (this.remoteStatus.status) {
        case this.callStatus.statuses.CALL_CONNECTED_TO_LOBBY:
          this.signalIfUser2Connected = true;
          this.showSpinner = true;
          Vue.swal({
            target: document.getElementById("main"),
            text: `${this.remoteStatus.whoConnected} ${this.$vuetify.lang.t(
              "$vuetify.main.psychologistDashboard.mainPage.webRTC.remoteStatusVueSwall"
            )}.`,
            icon: "info",
            timer: 4000,
            toast: true,
            position: "top-right",
            showConfirmButton: false,
          });

          break;
      }
    },
    connectToCall() {
      this.setCallStatus(this.callStatus.statuses.CALL_CONNECTED_TO_LOBBY);
      this.signalConnectToCall = !this.signalConnectToCall;
      this.createSignalIfConnectToCall();
      this.getUserMediaFromClient().then((stream) => {
        if (stream) {
          this.checkSizeOfWrapperVideoElement();
          this.createPeer(stream).then((data) => {
            switch (this.user.role) {
              case this.consts.USER_ROLE_PSYCHOLOGIST:
                this.userInSession.length === 2 && this.signalIfUser2Connected
                  ? this.sendOffer(data)
                  : (this.ourOffer = data);
                break;
              case this.consts.USER_ROLE_PATIENT:
                this.sendAnswer(data);
                break;
            }
          });
        }
      });
    },
    handleStopAndClearVideoTrackAndElements() {
      this.mediaDevices.currentVideoTrack.item.stop();
      this.mediaDevices.currentAudioTrack.item.stop();

      this.clientVideoElement.src = null;
      this.clientVideoElement.srcObject = null;
      this.wrapperVideoElement.src = null;
      this.wrapperVideoElement.srcObject = null;
    },
    hangUpCall() {
      if (this.peer) {
        this.peer.destroy();

        this.handleStopAndClearVideoTrackAndElements();

        this.ourStream.getTracks().map((track) => {
          track.stop();
        });

        if (this.callStatus.status === this.callStatus.statuses.CALL_HANGUP) {
          this.setCallStatus(
            this.callStatus.statuses.CALL_WAITING_FOR_CONNECTION_TO_CALL
          );
        }
      }
    },
    createPeer(stream) {
      return new Promise((resolve, reject) => {
        try {
          this.peer = new Peer({
            initiator: this.handleInitiator(),
            stream: stream,
            config: {
              iceServers: [
                {
                  urls: process.env.VUE_APP_STUN_ICE_SERVER,
                },
                {
                  urls: process.env.VUE_APP_TURN_ICE_SERVER,
                  credential: process.env.VUE_APP_STUN_ICE_SERVER_P,
                  username: process.env.VUE_APP_STUN_ICE_SERVER_L,
                },
              ],
            },
            trickle: false,
          });

          if (!this.handleInitiator() && this.remoteOffer) {
            this.peer.signal(this.remoteOffer);
            this.remoteOffer = null;
          }

          this.createPeerListeners(resolve);
        } catch (err) {
          reject(err);
        }
      });
    },
    createPeerListeners(resolve) {
      this.peer.on("signal", async (data) => {
        switch (this.user.role) {
          case "psychologist":
            this.ourOffer = data;
            resolve(data);
            break;
          case "patient":
            this.ourAnswer = data;
            resolve(data);
            break;
        }
      });

      // eslint-disable-next-line no-unused-vars
      this.peer.on("stream", (stream) => {
        let video = document.getElementById("video-player");
        if ("srcObject" in video) {
          video.srcObject = stream;
        } else {
          video.src = window.URL.createObjectURL(stream); // for older browsers
        }
        video.onloadedmetadata = () => {
          video.play();
        };
      });

      this.peer.on("connect", () => {
        this.setCallStatus(this.callStatus.statuses.CALL_STARTED);
      });

      // eslint-disable-next-line no-unused-vars
      // this.peer.on("track", (track, stream) => {});

      this.peer.on("close", () => {
        this.setCallStatus(this.callStatus.statuses.CALL_HANGUP);
        this.handleStopAndClearVideoTrackAndElements();
        this.signalIfUser2Connected = false;
        this.signalConnectToCall = !this.signalConnectToCall;
        this.showMessageIfUserTwoConnected = true;
        this.peer = null;
        this.ourOffer = null;
        this.ourAnswer = null;
        this.remoteAnswer = null;
        this.remoteOffer = null;

        this.ourStream = null;
      });

      this.peer.on("error", () => {}); // DON'T DELETE OR COMMENT THIS LINE
    },
    // eslint-disable-next-line no-unused-vars
    sendOffer(offer = null) {
      this.setCallStatus(this.callStatus.statuses.CALL_TRY_TO_CONNECT);
      this.callSocketChannel.whisper(
        "sendOffer",
        offer ? offer : this.ourOffer
      );
    },
    // eslint-disable-next-line no-unused-vars
    sendAnswer(answer) {
      this.callSocketChannel.whisper("sendAnswer", answer);
    },
    processOfCreateMainSocketChannel() {
      let mainSocketId = null;

      switch (this.user.role) {
        case this.consts.USER_ROLE_PSYCHOLOGIST:
          mainSocketId = this.user.id;
          break;
        case this.consts.USER_ROLE_PATIENT:
          mainSocketId = this.user.attached_psychologist.id;
          break;
      }

      this.createMainSocketChannel(mainSocketId).then((data) => {
        if (data[0].currentCallId && !this.callSocketChannel) {
          this.currentCallId = data[0].currentCallId;
          this.createCallSocketChannel(data[0].currentCallId);
          this.createSocketChannelListeners();
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    checkIfUserHaveCurrentCall(callId) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        let callIdFromUser = null;
        if (this.user.role === this.consts.USER_ROLE_PATIENT) {
          if (this.user.time_slots[0]) {
            callIdFromUser = this.user.time_slots[0].slots[0].call;
          }
          callId === callIdFromUser ? resolve(callId) : reject("");
        } else if (this.user.role === this.consts.USER_ROLE_PSYCHOLOGIST) {
          resolve(callId);
        }
      });
    },
    createMainSocketChannel(psychologistId) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-undef
        this.mainSocketChannel = Echo.join("psychologists." + psychologistId);

        this.mainSocketChannel
          .listen(".call-started", (data) => {
            if (data.currentCallId && !this.callSocketChannel) {
              this.checkIfUserHaveCurrentCall(data.currentCallId).then(
                (callid) => {
                  this.createCallSocketChannel(callid);
                  this.createSocketChannelListeners();
                }
              );
            }
          })
          .here((data) => {
            this.checkIfUserHaveCurrentCall(data[0].currentCallId).then(() => {
              resolve(data);
            });
          })
          // .joining((data) => {})
          // .leaving((data) => {})
          .error((error) => {
            reject(error);
          });
      });
    },
    // 5bdbaac3-64d8-41ea-8530-ad2ba716b1c0
    // 4c00999c-f63e-4530-bf79-e5c12ca755b1
    // eslint-disable-next-line no-unused-vars
    createCallSocketChannel(callId) {
      //eslint-disable-next-line no-undef
      this.callSocketChannel = Echo.join("calls." + callId);
      this.callSocketChannel
        .listen(".call-will-finish", () => {
          Vue.swal({
            target: document.getElementById("main"),
            text: this.$vuetify.lang.t(
              "$vuetify.main.psychologistDashboard.mainPage.webRTC.warningCallWillFinishVueSwall"
            ),
            icon: "info",
            toast: true,
            position: "top-right",
            button: "OK",
          });
        })
        .listen(".call-finished", () => {
          this.hangUpCall();
          // eslint-disable-next-line no-undef
          Echo.leave(this.callSocketChannel.name);
          if (this.user.role === this.consts.USER_ROLE_PSYCHOLOGIST) {
            this.updateCurrentClient(
              this.user.nearest_booked_time_slot_model.id
            );
          }
          this.callIsGoingNow = false;
          this.callSocketChannel = null;
          this.dialog = true;
        })
        .here((data) => {
          if (!data[0].going_right_now) {
            // eslint-disable-next-line no-undef
            Echo.leave(this.callSocketChannel.name);
          }
          this.callIsGoingNow = data[0].going_right_now;
          this.callIsGoingNow = true;
          if (data.length === 2 && !this.signalIfUser2Connected) {
            this.callSocketChannel.whisper("whoIsHere", JSON.stringify(true));
          }
          this.handleUserInSession("userHere", data);
        })
        .joining((data) => {
          this.handleUserInSession("userJoining", data);
        })
        .leaving((data) => {
          this.handleUserInSession("userLeave", data);
        });
    },
    createSocketChannelListeners() {
      this.callSocketChannel.listenForWhisper(
        "whoIsHere",
        // eslint-disable-next-line no-unused-vars
        (signal) => {
          if (!this.handleInitiator() && signal) {
            this.createSignalIfConnectToCall();
          }
        }
      );

      this.callSocketChannel.listenForWhisper(
        "signalIfUser2Connected",
        // eslint-disable-next-line no-unused-vars
        (signal) => {
          this.remoteStatus = JSON.parse(signal);
          if (this.ourOffer) {
            this.sendOffer();
          }
        }
      );

      // eslint-disable-next-line no-unused-vars
      this.callSocketChannel.listenForWhisper("sendOffer", (offer) => {
        switch (this.user.role) {
          // case "psychologist":
          //   console.log("bad case ??/??//?");
          //   break;
          case "patient":
            this.setCallStatus(this.callStatus.statuses.CALL_TRY_TO_CONNECT);
            this.peer ? this.peer.signal(offer) : (this.remoteOffer = offer);
            break;
        }
      });

      // eslint-disable-next-line no-unused-vars
      this.callSocketChannel.listenForWhisper("sendAnswer", (answer) => {
        switch (this.user.role) {
          case "psychologist":
            this.peer ? this.peer.signal(answer) : (this.remoteAnswer = answer);
            break;
          case "patient":
            break;
        }
      });
    },
    createSignalIfConnectToCall() {
      this.callSocketChannel.whisper(
        "signalIfUser2Connected",
        JSON.stringify({
          status: this.callStatus.status,
          whoConnected: `${this.user.name}`,
        })
      );
    },
    handleUserInSession(typeOfCall, userData) {
      switch (typeOfCall) {
        case "userHere":
          if (this.userInSession.length < 2) {
            this.userInSession = userData;
          }
          break;
        case "userJoining":
          this.userInSession.push(userData);
          break;
        case "userLeave":
          this.userInSession = this.userInSession.filter(
            (user) => user.id !== userData.id
          );
          break;
      }
    },
    handleInitiator() {
      return this.user.role === this.consts.USER_ROLE_PSYCHOLOGIST;
    },
    moveElement(movedElement, xPos, yPos) {
      movedElement.style.left = xPos + "px";
      movedElement.style.top = yPos + "px";
    },
    handleMove(event) {
      event.preventDefault();
      let video = this.clientVideoElement;
      let videoWrapper = document.getElementById("video__wrapper");

      event = event || window.event;
      let xPosition = event.clientX,
        yPosition = event.clientY,
        videoTop = video.style.top,
        videoLeft = video.style.left,
        videoWidth = video.offsetWidth,
        videoHeight = video.offsetHeight,
        wrapperWidth = videoWrapper.offsetWidth,
        wrapperHeight = videoWrapper.offsetHeight - 40;

      videoTop = videoTop.replace("px", "");
      videoLeft = videoLeft.replace("px", "");

      let xDiff = xPosition - videoLeft;
      let yDiff = yPosition - videoTop;

      document.onmousemove = (evt) => {
        evt = evt || window.event;
        let xPos = evt.clientX,
          yPos = evt.clientY,
          aX = xPos - xDiff,
          aY = yPos - yDiff;
        if (aX + videoWidth > wrapperWidth) aX = wrapperWidth - videoWidth;
        if (aY + videoHeight > wrapperHeight) aY = wrapperHeight - videoHeight;
        if (aX < 0) aX = 0;
        if (aY < 0) aY = 0;

        this.moveElement(video, aX, aY);
      };
    },
    /*
     * @param {string} wrapper id of the moving element.
     *
     * */
    handleStopMoving(wrapper) {
      document.getElementById(wrapper).style.cursor = "default";
      document.onmousemove = function () {};
    },
    getUserMediaFromClient() {
      return new Promise((resolve, reject) => {
        if (
          navigator.mediaDevices ||
          window.navigator.mediaDevices.getUserMedia ||
          window.navigator.mediaDevices.webkitGetUserMedia ||
          window.navigator.mediaDevices.mozGetUserMedia
        ) {
          navigator.mediaDevices
            .getUserMedia({
              video: {
                width: { min: 640, ideal: 1280, max: 1920 },
                height: { min: 480, ideal: 720, max: 1080 },
                facingMode: "user",
                frameRate: {
                  min: 24,
                  ideal: 30,
                  max: 60,
                },
              },
              audio: true,
            })
            .then((stream) => {
              this.setCurrentMediaTracks(stream);
              this.ourStream = stream;
              this.setClientStreamInClientVideoElement(stream);
              resolve(stream);
            })
            .catch((onerror) => {
              reject(onerror);
            });
        }
      });
    },
    setCurrentMediaTracks(stream) {
      let _this = this;
      if (stream) {
        stream.getTracks().map((track) => {
          switch (track.kind) {
            case "video":
              _this.mediaDevices.currentVideoTrack.label = track.label;
              _this.mediaDevices.currentVideoTrack.item = track;
              break;
            case "audio":
              _this.mediaDevices.currentAudioTrack.label = track.label;
              _this.mediaDevices.currentAudioTrack.item = track;
              break;
          }
        });
      }
    },
    handleSoundMethod(typeOfCall) {
      if (typeOfCall === "mute") {
        return (this.handleSoundValue = 0);
      } else {
        this.handleSound = !this.handleSound;
        if (this.handleSoundValue === 0) {
          this.handleSoundValue = 3;
        }
      }
    },
    checkFullScreen() {
      if (this.consts.sizeOfClientPlayer.fullScreen) {
        this.consts.sizeOfClientPlayer.fullScreen = false;
        this.setSizeOfClientVideoEl(
          this.consts.sizeOfClientPlayer.onDefault.type
        );
        this.wrapperVideoElement.classList.remove("openFullScreen");
        this.handleFullScreen = false;
      } else {
        this.consts.sizeOfClientPlayer.fullScreen = true;
        this.setSizeOfClientVideoEl(
          this.consts.sizeOfClientPlayer.onFullScreen.type
        );
      }
      this.checkSizeOfWrapperVideoElement();
    },
    handleVideoToFullScreen() {
      let requestFullScreen =
        this.wrapperVideoElement.requestFullscreen ||
        this.wrapperVideoElement.mozRequestFullScreen ||
        this.wrapperVideoElement.webkitRequestFullScreen ||
        this.wrapperVideoElement.msRequestFullscreen;

      let cancelFullScreen =
        document.exitFullscreen ||
        document.mozCancelFullScreen ||
        document.webkitExitFullscreen ||
        document.msExitFullscreen;

      if (
        !this.wrapperVideoElement.fullscreenElement &&
        !this.wrapperVideoElement.mozFullScreenElement &&
        !this.wrapperVideoElement.webkitFullscreenElement &&
        !this.wrapperVideoElement.msFullscreenElement &&
        !this.wrapperVideoElement.classList.contains("openFullScreen")
      ) {
        this.wrapperVideoElement.classList.add("openFullScreen");
        this.handleFullScreen = true;
        requestFullScreen.call(this.wrapperVideoElement);
      } else {
        this.wrapperVideoElement.classList.remove("openFullScreen");
        cancelFullScreen.call(document);
      }
    },
    stopVideoOrSound(typeOfTrackToStop) {
      switch (typeOfTrackToStop) {
        case "video":
          if (this.ourStream.getVideoTracks()[0].enabled) {
            this.ourStream.getVideoTracks()[0].enabled = false;
            if (this.mediaDevices.currentVideoTrack) {
              this.mediaDevices.currentVideoTrack.item.enabled = false;
            }
            this.handleVideo = !this.handleVideo;
            break;
          }
          this.ourStream.getVideoTracks()[0].enabled = true;
          this.mediaDevices.currentVideoTrack.item.enabled = true;
          this.handleVideo = !this.handleVideo;
          break;
        case "microphone":
          this.handleMicrophone = !this.handleMicrophone;
          if (this.ourStream.getAudioTracks()[0].enabled) {
            if (this.mediaDevices.currentAudioTrack) {
              this.mediaDevices.currentAudioTrack.item.enabled = false;
            }
            break;
          }
          this.mediaDevices.currentAudioTrack.item.enabled = true;
          this.ourStream.getAudioTracks()[0].enabled = true;
          break;
      }
    },
    muteVolume() {
      let videoEl = document.getElementById("video-player");
      videoEl.volume = this.handleSoundValue / 100;
    },
    setPositionOfClientVideoEl(typeOfPosition, positions = {}) {
      this.clientVideoElement.style.top = positions.y + "px";
      this.clientVideoElement.style.left = positions.x + "px";
    },
    checkSizeOfWrapperVideoElement() {
      let wrapperWidth = this.wrapperVideoElement.offsetWidth;
      let wrapperHeight = this.wrapperVideoElement.offsetHeight;

      let newPosition = new Promise((resolve, reject) => {
        let yPos =
          wrapperHeight -
          this.videoBtnsControl.offsetHeight -
          this.clientVideoElement.offsetHeight -
          20;

        let xPos = wrapperWidth - this.clientVideoElement.offsetWidth - 20;

        resolve({ x: xPos, y: yPos });
        reject(new Error("Something went wrong"));
      });

      newPosition.then((position) => {
        this.setPositionOfClientVideoEl(
          this.consts.sizeOfClientPlayer.onFullScreen.type,
          position
        );
      });
    },
    // eslint-disable-next-line no-unused-vars
    setSizeOfClientVideoEl(typeOfSize) {
      let video = document.getElementById("video-player-client");
      switch (typeOfSize) {
        case this.consts.sizeOfClientPlayer.onDefault.type:
          video.style.width = `${this.consts.sizeOfClientPlayer.onDefault.width}px`;
          video.style.height = `${this.consts.sizeOfClientPlayer.onDefault.height}px`;
          break;
        case this.consts.sizeOfClientPlayer.onFullScreen.type:
          if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              this.userAgent
            )
          ) {
            //mobile device
          } else {
            //not mobile devices
            video.style.width = `${this.consts.sizeOfClientPlayer.onFullScreen.resolutions.default.width}px`;
            video.style.height = `${this.consts.sizeOfClientPlayer.onFullScreen.resolutions.default.height}px`;
          }
          break;
      }
    },
    setTimeInVideo() {
      this.currentTime = new Date().toLocaleTimeString().slice(0, -3);
    },
  },
};
</script>

<style lang="sass" scoped>

.video__wrapper
  min-width: 300px
  max-width: 700px
  height: 350px
  position: relative
  &:hover
    .video__btn_controls
      opacity: 1
  & > .video__notification
    padding: 0 20px 0 20px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    height: 100%
    width: 100%
    border-radius: 20px
    background-color: rgb(38, 38, 38)
  &:fullscreen
    max-width: 100%
    height: 100%
    .video__player
      border-radius: 0
      -webkit-border-radius: 0

.video__notification_spinner
  position: absolute
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 100%
  width: 100%
  border-radius: 20px
  background-color: rgb(0,0,0,0)
  z-index: 2

.video__player
  width: 100%
  height: 100%
  background-color: #000000
  border-radius: 20px
  position: relative
  object-fit: cover

.video__btn_controls
  transition: all 0.3s ease
  width: 100%
  height: 100%
  opacity: 0
  max-height: 40px
  position: absolute
  bottom: 0
  border-bottom-left-radius: 20px
  border-bottom-right-radius: 20px
  padding: 0 15px 0 15px
  background-color: rgba(0,0,0,0.3)
  display: flex
  align-items: center
  justify-content: space-between
  z-index: 3

.icon__control
  transition: all 0.3s ease
  margin-right: 20px

.icon_gray
  color: #bfbfbf
  transition: color 0.3s ease
  &:hover
    color: #ffffff

.icon__control_settingns
  margin-right: 20px
  transition: all 0.2s ease
  &:hover
    cursor: pointer
    transform: rotate(60deg)

.icon_warning
  color: #b5475b
  transition: all 0.2s ease
  &:hover
    cursor: grab
    color: #e00832
    transform: rotate(15deg)

.slider
  height: 0
  width: 0
  visibility: hidden
  opacity: 0
  transition: all 0.3s ease

#sound-bar
  display: flex
  align-items: center
  margin-right: 20px
  & .icon__control
    margin: 0
  &:hover
    .slider
      visibility: visible
      width: 100px
      height: 40px
      opacity: 1

.video__player_client
  position: absolute
  border-radius: 20px
  background-color: #0e0e0e
  cursor: pointer
  object-fit: cover
  z-index: 3

#video-player-client-wrapper
  position: absolute
  width: 200px
  height: 100px

.current_time
  position: absolute
  left: 50%
  transform: translateX(-50%)

.settings
  padding: 10px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: flex-start
  width: 300px
  height: 130px
  position: absolute
  right: 20px
  bottom: 40px
  z-index: 5
  background-color: rgb(210, 210, 210)
  border-radius: 20px
  & > div
    width: 100%
</style>
